<template>
  <section>
    <r21 />
  </section>
</template>

<script>
  export default {
    metaInfo () {
      return {
        title: 'r21'
      }
    },

    components: {
      R21: () => import('@/components/R21s.vue')
    }
  }
</script>
